import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/ArticleLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3>{`Update June 7, 2018 - Hall of Shame`}</h3>
    <p>{`This article is a Hall of Shamer`}{`™`}{` for offering little to no value from the
moment it was written.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Type inference with sane interpolation (which takes expressions yay)! Make for
less nasty NSLog.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Dictionary & Array literal syntax. Legit. More akin to PHP than JS by syntax.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`let & var seem to hold for reference semantics not value semantics. Think
Scala's val & var.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`TODO: Does for in take advantage of NSFastIteration protocol? In which case,
users could provide iterables?`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Expression support for control flow! Pattern matching!`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Ranges. Appear to be lazily computed as expected.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Nice overloading of `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`...`}</code>{` to represent variadic functions.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`First class functions!`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The closure block syntax is a little odd overloading the in keyword to
separate the interface from the implementation.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Single line statement closures are implicit return types`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Classes look freakishly like ES6/Typescript classes.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Explicit override for subclasses.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Weird implicit "newValue" in setters. You can explicitly set the name if you
like though.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`willSet/didSet I imagine this was done to keep binding logic out of
getters/setters.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The existential `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`?`}</code>{` operator is just great, reminds me of coffee script.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Named parameters only exists in methods? Seems like a choice for Objective-C
interoperability, feels odd that functions don't have the same restriction.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`structs == value classes`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`enums + Pattern Matching reminds me a lot of Scala's case classes.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`extensions lots like C# so far as I can tell, I'm guessing this will be used
to replace Objective-C Categories. Extending built-ins FTW!`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Protocols now restrict consumers to consuming only methods in the protocol.
Awesome!`}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      